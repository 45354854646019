import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Typography, Progress } from "antd";
import { motion } from "framer-motion"

import { ExclamationCircleFilled } from "@ant-design/icons";

const { confirm } = Modal;
const { Paragraph, Title } = Typography;

export default function AutomaticLoading({ getAutomaticLoad, autoLoadProgress }) {
  const [mail, setMail] = useState("")
  const [debuggin, setDebuggin] = useState(false)

  const variantsAutomatic = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  };

  const showConfirm = () => {
    confirm({
      title: "Atención",
      icon: <ExclamationCircleFilled />,
      content: "Da click en Aceptar para iniciar el proceso de carga automática.",
      centered: true,
      okText: "Aceptar",
      cancelText: "Cancelar",
      onOk() {
        sendAutomaticLoad()
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const sendAutomaticLoad = () => {
    if (mail === "") {
      Modal.warning({
        title: "Atención",
        content: "Debe introducir un correo electronico para recibir la información.",
        centered: true
      })
      return
    }

    getAutomaticLoad(mail, debuggin)
  }
  const onMailChange = (mail) => {
    setMail(mail)
  }
  const onCheckChange = (e) => {
    setDebuggin(e.target.checked)
  }

  return (
    <motion.div 
      className="automatic-loading-container"
      variants={variantsAutomatic}
      initial="hidden"
      animate="visible"
      transition={{
        duration: 0.5,
      }}
    >
      <Title level={5}>Carga Automática de Listas de Marcación</Title>
      <div className="automatic-description-container">
        <Paragraph>
          En esta funcionalidad podrás ejecutar a demanda el proceso de Carga
          Automática de Listas de Marcación. Asegurate que los archivos CSV de
          carga se encuentren cargados previamente en el SFTP en la ruta:
          /GENESYS/LMs/
        </Paragraph>
      </div>
      <div className="mail-container"
        style={{ padding: "0rem 5rem", marginTop: "2rem" }}
      >
        <Form style={{ width: "40%" }}>
          <Form.Item
            name="debbugin"
          >
            <Checkbox onChange={onCheckChange}>Realizar depuración de las Listas de Marcación</Checkbox>
          </Form.Item>
          <Form.Item
            label="Email al que se enviara la información"
            name="mail"
            rules={[
              {
                required: true,
                type: "email",
                message: 'Intoduzca un correo electrónico válido.',
              }
            ]}
          >
            <Input onChange={(e) => onMailChange(e.target.value)} />
          </Form.Item>
        </Form>
      </div>
      <div className="btn-progress-bar-container">
        <Button type="primary" size="large" onClick={() => showConfirm()}>
          Ejecutar carga Automática
        </Button>
        <Progress
          percent={autoLoadProgress}
          size={[330, 20]}
          style={{ marginLeft: "14rem" }}
        />
      </div>
    </motion.div>
  );
}
