import React, { useState, useEffect } from "react";
import { Card, ConfigProvider, Input, DatePicker, Space, Table } from "antd";
import locale from "antd/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { motion } from "framer-motion";

const { RangePicker } = DatePicker;
const { Search } = Input;

export default function Campaigns({
                                    getCampaigns,
                                    getKeyAccountsCampaigns,
                                    getManualsCampaigns,
                                    getWhatsAppCampaigns,
                                    getDigitalsCampaigns,
                                    campaigns,
                                    getCampaignsData,
                                    getAgentsData,
                                    getKeyAccountsHistoricalData,
                                    getKeyAccountsAgentsData,
                                    getManualsHistoricalData,
                                    getWhatsAppCampaignsData,
                                    getWhatsAppAgentsData,
                                    getManualsAgentsData,
                                    getDigitalsHistoricalData,
                                    getDigitalsAgentsData,
                                    disableDatepicker,
                                    today,
                                    currentPage,
                                    selectedDates,
                                    setSelectedDates,
                                    datesChange,
                                  }) {
  const start = () => {
    setSelectedRowKeys([]);
    setSelectedDates([null, null]);
    setSelectedCampaigns([]);
    setSearchText("")
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // rowSelection object indicates the need for row selection

  // const rowSelection = {
  //   // selectedRowKeys,
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     // const filterNames = selectedRows.map((row) => {
  //     //   return row.name;
  //     // });
  //     // setSelectedRowKeys(filterNames)
  //     // getCampaignsData(selectedRows);
  //     // getAgentsData(selectedRows);
  //     // getKeyAccountsHistoricalData(selectedRows);
  //     // getKeyAccountsAgentsData(selectedRows);
  //     // getDigitalsHistoricalData(selectedRows);
  //     // getDigitalsAgentsData(selectedRows);
  //     // setCompaignsSelected(filterIds)
  //   },
  //   // getCheckboxProps: (record) => ({
  //   //   disabled: record.name === "Disabled User",
  //   //   // Column configuration not to be checked
  //   //   name: record.name,
  //   // }),
  // };
  const [selectionType] = useState("checkbox");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  // eslint-disable-next-line
  const [sCampaigns, setSelectedCampaigns] = useState([]);
  const [searchText, setSearchText] = useState("")
  // eslint-disable-next-line
  const [date, setDate] = useState([]);
  const variantsCampaigns = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  };
  const columns = [
    {
      title: "Campaña",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name).toLowerCase().includes(value.toLowerCase())
      },
      className: "campaigns-column",
    },
    {
      title: "Estatus",
      dataIndex: "status",
      key: "status",
      // width: 115
    },
  ];

  useEffect(() => {
    if (disableDatepicker) {
      // disable datepicker
      setDate(today);
    }
    if (disableDatepicker) {
      setPage(1);
    }
  }, [disableDatepicker, today]);
  useEffect(() => {
    start();
    // eslint-disable-next-line
  }, [currentPage]);


  // const onSearch = (value) => {
  //   if (value === "") {
  //     setIsSearching(false);
  //     return;
  //   }

  //   setIsSearching(true);
  //   const filterCampaigns = campaigns.filter((camp) => {
  //     return camp.id.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setFilteredCampaigns(filterCampaigns);
  // };
  // const onSearchChange = (value) => {
  //   if (value === "") {
  //     setIsSearching(false);
  //     return;
  //   }

  //   setIsSearching(true);
  //   const filterCampaigns = campaigns.filter((camp) => {
  //     return camp.name.toLowerCase().includes(value);
  //   });
  //   setFilteredCampaigns(filterCampaigns);
  // };
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    // let newArray = isSearching ? Array.from(sCampaigns) : Array.from(selectedRows);
    let newArray = Array.from(selectedRows);
    // let newArray = Array.from(selCampaigns);
    Array.prototype.push.apply(newArray, selectedRows);
    const filterNewArray = newArray.filter((arrItem, index) => {
      return newArray.indexOf(arrItem) === index;
    });
    // getSelectedCampaigns(filterNewArray)
    setSelectedCampaigns(filterNewArray);
    // Paasing to the correct getData Function
    getCampaignsData(filterNewArray);
    getAgentsData(filterNewArray);
    getKeyAccountsHistoricalData(filterNewArray);
    getManualsHistoricalData(filterNewArray)
    getKeyAccountsAgentsData(filterNewArray);
    getManualsAgentsData(filterNewArray);
    getWhatsAppCampaignsData(filterNewArray);
    getWhatsAppAgentsData(filterNewArray);
    getDigitalsHistoricalData(filterNewArray);
    getDigitalsAgentsData(filterNewArray);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChange = (dates) => {
    if (dates) {
      setPage(1);
      if (
        currentPage === "1-Historicos" ||
        currentPage === "2-Tiempo Real" ||
        currentPage === "3-Historicos" ||
        currentPage === "4-Tiempo Real"
      ) {
        getCampaigns(dates, "tradicional");
      } else if (
        currentPage === "5-Historicos" ||
        currentPage === "6-Tiempo Real" ||
        currentPage === "7-Historicos" ||
        currentPage === "8-Tiempo Real"
      ) {
        getCampaigns(dates, "hogar");
      } else if (
        currentPage === "9-Historicos" ||
        currentPage === "10-Tiempo Real" ||
        currentPage === "11-Historicos" ||
        currentPage === "12-Tiempo Real"
      ) {
        getKeyAccountsCampaigns(dates);
      } else if (
        currentPage === "13-Historicos" ||
        currentPage === "14-Tiempo Real" ||
        currentPage === "15-Historicos" ||
        currentPage === "16-Tiempo Real"
      ) {
        getManualsCampaigns(dates)
      } else if (
        currentPage === "17-Historicos" ||
        currentPage === "18-Tiempo Real" ||
        currentPage === "19-Historicos" ||
        currentPage === "20-Tiempo Real"
      ) {
        getWhatsAppCampaigns(dates, "WHATSAPP")
      } else if (currentPage === "21-Historicos") {
        getDigitalsCampaigns(dates, true);
      } else if (
        currentPage === "22-Historicos" ||
        currentPage === "23-Tiempo Real"
      ) {
        getDigitalsCampaigns(dates, false, true);
      } else if (
        currentPage === "24-Historicos" ||
        currentPage === "25-Tiempo Real"
      ) {
        getDigitalsCampaigns(dates);
      } else {
        setSelectedDates([null, null]);
      }
      if (
        currentPage === "1-Historicos" ||
        currentPage === "3-Historicos" ||
        currentPage === "5-Historicos" ||
        currentPage === "7-Historicos" ||
        currentPage === "9-Historicos" ||
        currentPage === "11-Historicos" ||
        currentPage === "13-Historicos" ||
        currentPage === "15-Historicos" ||
        currentPage === "17-Historicos" ||
        currentPage === "19-Historicos" ||
        currentPage === "21-Historicos" ||
        currentPage === "22-Historicos" ||
        currentPage === "24-Historicos"
      ) {
        setSelectedDates(dates);
      } else {
        setSelectedDates([null, null]);
      }
    }
  };
  const onDateRangeClick = () => {
    setSelectedDates([null, null]);
  };
  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   // return current < dayjs().endOf('day');
  //   return current > dayjs();
  // };

  return (
    <motion.div
      className="campaigns-container"
      variants={variantsCampaigns}
      initial="hidden"
      animate="visible"
      transition={{
        duration: 1,
      }}
    >
      <Card
        style={{
          height: "86.5vh",
          width: "99%",
          cursor: "default",
          border: "1px solid #01479B",
        }}
        bodyStyle={{
          width: "100%",
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        hoverable
      >
        <Space direction="vertical">
          {!disableDatepicker ? (
            <ConfigProvider locale={locale}>
              <RangePicker
                value={selectedDates}
                key={"data-datepicker"}
                allowClear={false}
                onChange={onChange}
                onClick={() => onDateRangeClick()}
                disabledDate={(current) => {
                  if (current >= dayjs().startOf("day")) {
                    return true;
                  }
                }}
                style={{
                  width: "100%",
                }}
              />
            </ConfigProvider>
          ) : (
            <DatePicker
              key={"today-datepicker"}
              disabled={true}
              defaultValue={dayjs()
                .set("hours", 0)
                .set("minutes", 0)
                .set("seconds", 0)}
              format={"YYYY-MM-DD"}
              style={{
                width: "100%",
              }}
            />
          )}

          <Search
            value={searchText}
            placeholder="Campaña..."
            allowClear={false}
            enterButton
            // onSearch={onSearch}
            // onChange={(e) => onSearchChange(e.target.value)}
            onSearch={(value) => setSearchText(value)}
            onChange={(e) => setSearchText(e.target.value)}
            style={{
              width: "100%",
            }}
          />
        </Space>
        <div className="table-container" style={{ height: "60vh" }}>
          <Table
            dataSource={
              campaigns.length > 0
                ? campaigns
                : []
            }
            columns={columns}
            rowKey="id"
            scroll={{ y: 450 }}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            pagination={{
              defaultPageSize: 2,
              current: page,
              pageSize: pageSize,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
          />
        </div>
      </Card>
    </motion.div>
  );
}