const times = [
    {
        value: '07:00',
        label: '07:00'
    },
    {
        value: '07:10',
        label: '07:10'
    },
    {
        value: '07:20',
        label: '07:20'
    },
    {
        value: '07:30',
        label: '07:30'
    },
    {
        value: '07:40',
        label: '07:40'
    },
    {
        value: '07:50',
        label: '07:50'
    },
    {
        value: '08:00',
        label: '08:00'
    },
    {
        value: '08:10',
        label: '08:10'
    },
    {
        value: '08:20',
        label: '08:20'
    },
    {
        value: '08:30',
        label: '08:30'
    },
    {
        value: '08:40',
        label: '08:40'
    },
    {
        value: '08:50',
        label: '08:50'
    },
    {
        value: '09:00',
        label: '09:00'
    },
    {
        value: '09:10',
        label: '09:10'
    },
    {
        value: '09:20',
        label: '09:20'
    },
    {
        value: '09:30',
        label: '09:30'
    },
    {
        value: '09:40',
        label: '09:40'
    },
    {
        value: '10:00',
        label: '10:00'
    },
    {
        value: '10:10',
        label: '10:10'
    },
    {
        value: '10:20',
        label: '10:20'
    },
    {
        value: '10:30',
        label: '10:30'
    },
    {
        value: '10:40',
        label: '10:40'
    },
    {
        value: '10:50',
        label: '10:50'
    },
    {
        value: '11:00',
        label: '11:00'
    },
    {
        value: '11:10',
        label: '11:10'
    },
    {
        value: '11:20',
        label: '11:20'
    },
    {
        value: '11:30',
        label: '11:30'
    },
    {
        value: '11:40',
        label: '11:40'
    },
    {
        value: '11:50',
        label: '11:50'
    },
    {
        value: '12:00',
        label: '12:00'
    },
    {
        value: '12:10',
        label: '12:10'
    },
    {
        value: '12:20',
        label: '12:20'
    },
    {
        value: '12:30',
        label: '12:30'
    },
    {
        value: '12:40',
        label: '12:40'
    },
    {
        value: '13:00',
        label: '13:00'
    },
    {
        value: '13:10',
        label: '13:10'
    },
    {
        value: '13:20',
        label: '13:20'
    },
    {
        value: '13:30',
        label: '13:30'
    },
    {
        value: '13:40',
        label: '13:40'
    },
    {
        value: '13:50',
        label: '13:50'
    },
    {
        value: '14:00',
        label: '14:00'
    },
    {
        value: '14:10',
        label: '14:10'
    },
    {
        value: '14:20',
        label: '14:20'
    },
    {
        value: '14:30',
        label: '14:30'
    },
    {
        value: '14:40',
        label: '14:40'
    },
    {
        value: '14:50',
        label: '14:50'
    },
    {
        value: '15:00',
        label: '15:00'
    },
    {
        value: '15:10',
        label: '15:10'
    },
    {
        value: '15:20',
        label: '15:20'
    },
    {
        value: '15:30',
        label: '15:30'
    },
    {
        value: '15:40',
        label: '15:40'
    },
    {
        value: '15:50',
        label: '15:50'
    },
    {
        value: '16:00',
        label: '16:00'
    },
    {
        value: '16:10',
        label: '16:10'
    },
    {
        value: '16:20',
        label: '16:20'
    },
    {
        value: '16:30',
        label: '16:30'
    },
    {
        value: '16:40',
        label: '16:40'
    },
    {
        value: '16:50',
        label: '16:50'
    },
    {
        value: '17:00',
        label: '17:00'
    },
    {
        value: '17:10',
        label: '17:10'
    },
    {
        value: '17:20',
        label: '17:20'
    },
    {
        value: '17:30',
        label: '17:30'
    },
    {
        value: '17:40',
        label: '17:40'
    },
    {
        value: '17:50',
        label: '17:50'
    },
    {
        value: '18:00',
        label: '18:00'
    },
    {
        value: '18:10',
        label: '18:10'
    },
    {
        value: '18:20',
        label: '18:20'
    },
    {
        value: '18:30',
        label: '18:30'
    },
    {
        value: '18:40',
        label: '18:40'
    },
    {
        value: '18:50',
        label: '18:50'
    },
    {
        value: '19:00',
        label: '19:00'
    },
    {
        value: '19:10',
        label: '19:10'
    },
    {
        value: '19:20',
        label: '19:20'
    },
    {
        value: '19:30',
        label: '19:30'
    },
    {
        value: '19:40',
        label: '19:40'
    },
    {
        value: '19:50',
        label: '19:50'
    },
    {
        value: '20:00',
        label: '20:00'
    },
    {
        value: '20:10',
        label: '20:10'
    },
    {
        value: '20:20',
        label: '20:20'
    },
    {
        value: '20:30',
        label: '20:30'
    },
    {
        value: '20:40',
        label: '20:40'
    },
    {
        value: '20:50',
        label: '20:50'
    },
    {
        value: '21:00',
        label: '21:00'
    },
    {
        value: 'Closed',
        label: 'Closed'
    },
]

export default times