import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Typography, Button, Upload, Progress, message } from 'antd';
import { motion } from "framer-motion";
import { ResumenCarga } from './ResumenCarga';
import axios from 'axios';
const { Title, Text } = Typography;

export const TipoAgentless = ({Settings, auth}) => {

    const [view, setView] = useState(false);
    const [submit, setSubmit] = useState(false);

    const variantsCHistorical = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    };

    const [fileList, setFileList] = useState([]);
    const [name, setName] = useState("");
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setName("");
        },
        beforeUpload: (file) => {
            setName(file.name);
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const [messageApi, contextHolder] = message.useMessage();
    const errorMen = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
        });
    };

    const uploadFile = async () => {
        if(fileList.length === 0) {
            errorMen("Sube un archivo CSV para continuar");
        } 
        else {
            try {
                const formData = new FormData();
                formData.append(name, fileList[0]);
                await axios.post(`${Settings.redfieldPushDomain}/notifications/uploadAgentless`, formData, {
                    auth: {
                        username: auth.username,
                        password: auth.password,
                    }
                });
                setSubmit(true);
                cargandoBarra();
            } 
            catch (error) {
                console.log(error);
                errorMen(error.response.data.error);
            }
        }
    }

    // Api Cargando
    const [progreso, setProgreso] = useState(0);
    const cargandoBarra = async () => {
        let num = 0;
        while(num<100) {
            try {
                const res = await axios.get(`${Settings.redfieldPushDomain}/notifications/upload/status/${name}`, {
                    auth: {
                        username: auth.username,
                        password: auth.password,
                    }
                });
                if(res.status===200) {
                    setProgreso((res.data).toFixed(0));
                    num = res.data
                    if(res.data===100) {
                        setTimeout(() => {
                            setView(true);
                        }, 4000);
                    }
                }
            }
            catch (error) {
                console.log(error)
            }
            await new Promise((resolve) => setTimeout(resolve, 3000));
        }
    }

    function Formulario() {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "30px"}}>
                <Text style={{fontSize: "16px", fontWeight: "500"}}>Defina los siguientes campos de envío</Text>

                <div style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                    <Text style={{fontSize: "16px", marginRight: "10px"}}>Archivo Fuente CSV:</Text>
                    <Upload {...props} accept=".csv" maxCount={1}>
                        <Button icon={<UploadOutlined />} disabled={submit || name!==""}>Subir CSV</Button>
                    </Upload>
                </div>

                <div style={{marginTop: "20px"}}>
                    <Button type="primary" onClick={uploadFile} disabled={submit}>Ejecutar Carga Masiva</Button>
                </div>

                {submit &&
                    <>
                        <Progress type="circle" percent={progreso} style={{marginTop: "40px"}} />
                        <Text>Cargando...</Text>
                    </>
                }
            </div>
        )
    }

    return (
        <motion.div
            className="planifications-container"
            variants={variantsCHistorical}
            initial="hidden"
            animate="visible"
            transition={{
                duration: 1,
            }}
        >
            <Title level={5}>Envío de Mensajes Tipo Agentless Sin Marcación</Title>

            {view ? <ResumenCarga name={name} Settings={Settings} auth={auth} /> : <Formulario />}
            
            {contextHolder}
        </motion.div>
    )
}
