import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, Modal, Form, Input } from "antd";
import { motion } from "framer-motion";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { LoginOutlined } from "@ant-design/icons";
import Loader from "../components/common/Loader";

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 16,
  },
};

export default function Login({ Settings }) {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  };

  useEffect(() => {
    if (Cookie.get("userData")) {
      navigate("/redfield/dashboard");
    }
    // eslint-disable-next-line
  }, []);

  const onFinish = (values) => {
    setLoading(true)
    const url = `${Settings.redfieldDomain}${Settings.loginPath}`
    // const staticUrl = 'https://gepp.redfield.kranon.cloud/auth/login'
    axios
      .post(url, values)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          Cookie.set("userData", JSON.stringify(values), { expires: 1 });
          navigate("/redfield/dashboard", { replace: true });
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
        if (err.response && err.response.status === 401) {
          Modal.warning({
            title: "Aviso",
            content: "Inicio de sesión inválido. Verifique los datos y vuelva a intentarlo",
            centered: true
          })
        } else {
          Modal.error({
            title: "Algo salió mal, contacte a soporte",
            content: err.message,
            centered: true
          })
        }
      });
  };

  return (
    <div className="login-main-container">
      <div className="login-container" style={{ width: "40%", height: "35%" }}>
        <motion.div
          className="logo-container"
          variants={variants}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1.5,
          }}
        >
          <img src={`${Settings.staticMedia}gepp-logo-v1.png`} alt="logo-img" />
        </motion.div>
        <motion.div
          className="card-container"
          variants={variants}
          initial="hidden"
          animate="visible"
          transition={{
            duration: 1.5,
          }}
        >
          <Card
            hoverable
            style={{
              cursor: "default",
              border: "2px solid #CCCCCC",
              width: "100%"
            }}
            bodyStyle={{
              background: "#01479B",
              borderRadius: "6px",
            }}
          >
            <Form {...layout} style={{ color: "#FCFCFC" }} onFinish={onFinish}>
              <Form.Item
                name="username"
                label="Usuario:"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <div
                  className="input-container"
                  style={{ borderBottom: "2px solid #FCFCFC" }}
                >
                  <Input bordered={false} style={{ color: "#FCFCFC" }} />
                </div>
              </Form.Item>
              <Form.Item
                name="password"
                label="Contraseña:"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <div
                  className="input-container"
                  style={{ borderBottom: "2px solid #FCFCFC" }}
                >
                  <Input.Password
                    bordered={false}
                    style={{ color: "#FCFCFC" }}
                  />
                </div>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  icon={<LoginOutlined />}
                >
                  Enter
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </motion.div>
      </div>
      {loading ? <Loader /> : null}
    </div>
  );
}
