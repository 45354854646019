import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import Main from "./views/Main";
import Login from "./views/Login";

import Settings from "./config";

function App() {

  return (
    <Router>
      <Routes>
        <Route 
          path="/redfield/login"
          element={
            <Login 
              Settings={Settings} 
            />
          }
        />
        <Route 
          path="/redfield/dashboard"
          element={
            <Main 
              Settings={Settings} 
            />
          }
        />
        <Route path="*" element={<Navigate to="/redfield/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
