import React from "react";

export default function GridFooter({ sequenceLength }) {
  return (
    <div
      className="header-footer"
      style={ sequenceLength >= 6 && sequenceLength !== 0 ?
        {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "0.50rem",
          margin: "0.5rem 0rem",
          paddingRight:"1.8rem"
        }
        :{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        margin: "0.5rem 0rem",
      }}
    >
      <div
        className="start-end"
        style={{ width: "14%", display: "flex", gap: "0.3rem"    }}
      ></div>
      <div
        className="start-end"
        style={{ width: "12%", display: "flex", gap: "0.3rem"    }}
      >
        <div
          className="start"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Inicio
          </p>
        </div>
        <div
          className="end"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Fin
          </p>
        </div>
      </div>
      <div
        className="start-end"
        style={{ width: "12%", display: "flex", gap: "0.3rem"    }}
      >
        <div
          className="start"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Inicio
          </p>
        </div>
        <div
          className="end"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Fin
          </p>
        </div>
      </div>
      <div
        className="start-end"
        style={{ width: "12%", display: "flex", gap: "0.3rem"    }}
      >
        <div
          className="start"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Inicio
          </p>
        </div>
        <div
          className="end"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Fin
          </p>
        </div>
      </div>
      <div
        className="start-end"
        style={{ width: "12%", display: "flex", gap: "0.3rem"    }}
      >
        <div
          className="start"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Inicio
          </p>
        </div>
        <div
          className="end"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Fin
          </p>
        </div>
      </div>
      <div
        className="start-end"
        style={{ width: "12%", display: "flex", gap: "0.3rem"    }}
      >
        <div
          className="start"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Inicio
          </p>
        </div>
        <div
          className="end"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Fin
          </p>
        </div>
      </div>
      <div
        className="start-end"
        style={{ width: "12%", display: "flex", gap: "0.3rem"    }}
      >
        <div
          className="start"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Inicio
          </p>
        </div>
        <div
          className="end"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Fin
          </p>
        </div>
      </div>
      <div
        className="start-end"
        style={{ width: "12%", display: "flex", gap: "0.3rem"    }}
      >
        <div
          className="start"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Inicio
          </p>
        </div>
        <div
          className="end"
          style={{
            width: "50%",
            padding: "1px",
            border: "1px solid #01479B",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "0em",
            }}
          >
            Fin
          </p>
        </div>
      </div>
    </div>
  );
}
