import React from "react";
import { Select } from "antd";

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export default function SequenceItem({ sequence, times, sequencesState, setSequencesState, sequenceLength }) {

  const changeTimeControlIndividualy = (value, day, start, end, name) => {
    const sequenceCopy = Array.from(sequencesState)
    switch(day) {
      case 'monday':
        if (start && !end) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: value,
                  end: seq.sequenceTimes[day].end
                }
              }
            } : {
              ...seq
            }
          })

          setSequencesState(selectedSequence)
        }
        if (end && !start) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: seq.sequenceTimes[day].start,
                  end: value
                }
              }
            } : {
              ...seq
            }
          })

          setSequencesState(selectedSequence)
        }
        break;
      case 'tuesday':
        if (start && !end) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: value,
                  end: seq.sequenceTimes[day].end
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        if (end && !start) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: seq.sequenceTimes[day].start,
                  end: value
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        break;
      case 'wednesday':
        if (start && !end) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: value,
                  end: seq.sequenceTimes[day].end
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        if (end && !start) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: seq.sequenceTimes[day].start,
                  end: value
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        break;
      case 'thursday':
        if (start && !end) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: value,
                  end: seq.sequenceTimes[day].end
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        if (end && !start) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: seq.sequenceTimes[day].start,
                  end: value
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        break;
      case 'friday':
        if (start && !end) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: value,
                  end: seq.sequenceTimes[day].end
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        if (end && !start) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: seq.sequenceTimes[day].start,
                  end: value
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        break;
      case 'saturday':
        if (start && !end) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: value,
                  end: seq.sequenceTimes[day].end
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        if (end && !start) {
          const selectedSequence = sequenceCopy.map(seq => {
            return seq.name === name ? {
              ...seq,
              sequenceTimes: {
                ...seq.sequenceTimes,
                [day]: {
                  start: seq.sequenceTimes[day].start,
                  end: value
                }
              }
            } : {
              ...seq
            }
          })
          setSequencesState(selectedSequence)
        }
        break;
      case 'sunday':
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="content"
      style={ sequenceLength <= 6 && sequenceLength !== 0 ?
        {
          width: "100%",
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: "1rem",
          paddingRight: "1.2rem"
        }
        : {
          width: "100%",
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: "1rem",
      }}
    >
      <div
        className="sequence-name"
        style={{
          width: "13.7%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.2rem",
          padding: "5px",
          marginTop: "1.5rem",
          borderRadius: "6px",
          fontSize: "12px",
          background: "#01479B",
          color: "#FCFCFC",
        }}
      >
        {sequence.name}
      </div>
      <div
        className="start-end-inputs-container"
        style={{
          width: "12%",
          height: "5%",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
        }}
      >
        <Select
          id={`${sequence.name}-start`}
          showSearch
          options={times}
          className="start"
          value={sequence.sequenceTimes.monday.start}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "monday",
              true,
              false,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          suffixIcon={false}
        />
        <Select
          id={`${sequence.name}-end`}
          showSearch
          options={times}
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          value={sequence.sequenceTimes.monday.end}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "monday",
              false,
              true,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          suffixIcon={false}
        />
      </div>
      <div
        className="start-end-inputs-container"
        style={{
          width: "12%",
          height: "5%",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
        }}
      >
        <Select
          id={`${sequence.name}-start`}
          showSearch
          options={times}
          className="start"
          value={sequence.sequenceTimes.tuesday.start}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "tuesday",
              true,
              false,
              sequence.name
            )
          }
           filterOption={filterOption}
          size="small"
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          suffixIcon={false}
        />
        <Select
          id={`${sequence.name}-end`}
          showSearch
          options={times}
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          value={sequence.sequenceTimes.tuesday.end}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "tuesday",
              false,
              true,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          suffixIcon={false}
        />
      </div>
      <div
        className="start-end-inputs-container"
        style={{
          width: "12%",
          height: "5%",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
        }}
      >
        <Select
          id={`${sequence.name}-start`}
          showSearch
          options={times}
          className="start"
          value={sequence.sequenceTimes.wednesday.start}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "wednesday",
              true,
              false,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          suffixIcon={false}
        />
        <Select
          id={`${sequence.name}-end`}
          showSearch
          options={times}
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          value={sequence.sequenceTimes.wednesday.end}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "wednesday",
              false,
              true,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          suffixIcon={false}
        />
      </div>
      <div
        className="start-end-inputs-container"
        style={{
          width: "12%",
          height: "5%",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
        }}
      >
        <Select
          id={`${sequence.name}-start`}
          showSearch
          options={times}
          className="start"
          value={sequence.sequenceTimes.thursday.start}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "thursday",
              true,
              false,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          suffixIcon={false}
        />
        <Select
          id={`${sequence.name}-end`}
          showSearch
          options={times}
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          value={sequence.sequenceTimes.thursday.end}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "thursday",
              false,
              true,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          suffixIcon={false}
        />
      </div>
      <div
        className="start-end-inputs-container"
        style={{
          width: "12%",
          height: "5%",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
        }}
      >
        <Select
          id={`${sequence.name}-start`}
          showSearch
          options={times}
          className="start"
          value={sequence.sequenceTimes.friday.start}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "friday",
              true,
              false,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          suffixIcon={false}
        />
        <Select
          id={`${sequence.name}-end`}
          showSearch
          options={times}
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          value={sequence.sequenceTimes.friday.end}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "friday",
              false,
              true,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          suffixIcon={false}
        />
      </div>
      <div
        className="start-end-inputs-container"
        style={{
          width: "12%",
          height: "5%",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
        }}
      >
        <Select
          id={`${sequence.name}-start`}
          showSearch
          options={times}
          className="start"
          value={sequence.sequenceTimes.saturday.start}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "saturday",
              true,
              false,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          suffixIcon={false}
        />
        <Select
          id={`${sequence.name}-end`}
          showSearch
          options={times}
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          value={sequence.sequenceTimes.saturday.end}
          onSelect={(value) =>
            changeTimeControlIndividualy(
              value,
              "saturday",
              false,
              true,
              sequence.name
            )
          }
          filterOption={filterOption}
          size="small"
          suffixIcon={false}
        />
      </div>
      <div
        className="start-end-inputs-container"
        style={{
          width: "12%",
          height: "5%",
          display: "flex",
          flexDirection: "row",
          gap: "0.2rem",
        }}
      >
        <Select
          id={`${sequence.name}-start`}
          options={times}
          className="start"
          value={sequence.sequenceTimes.sunday.start}
          size="small"
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          suffixIcon={false}
        />
        <Select
          id={`${sequence.name}-end`}
          options={times}
          style={{
            width: "50%",
            fontSize: "7px",
          }}
          value={sequence.sequenceTimes.sunday.end}
          size="small"
          suffixIcon={false}
        />
      </div>
    </div>
  );
}
