import React, { useEffect, useState } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined, ArrowUpOutlined, DownloadOutlined } from '@ant-design/icons';
import { Typography, Card, Statistic, Table, message, Tag, Button } from 'antd';
import '../../styles/Login.css';
import axios from 'axios';
const { Text } = Typography;

export const ResumenCarga = ({name, Settings, auth}) => {

    const [validColumnUno, setValidColumnUno] = useState(false);
    const [validColumnDos, setValidColumnDos] = useState(false);
    const [validColumnTres, setValidColumnTres] = useState(false);
    const [validColumnCuatro, setValidColumnCuatro] = useState(false);
    const [validColumnCinco, setValidColumnCinco] = useState(false);
    const [validColumnSeis, setValidColumnSeis] = useState(false);
    const [validColumnSiete, setValidColumnSiete] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();
    const errorMen = () => {
        messageApi.open({
            type: 'error',
            content: 'Error al cargar los datos, intente nuevamente.',
        });
    };

    // Api Data
    const [dataApi, setDataApi] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [total, setTotal] = useState(0);
    const [totalError, setTotalError] = useState(0);

    const apiFindData = async () => {
        setCargando(true);
        try {
            const res = await axios.get(`${Settings.redfieldPushDomain}/notifications/csv/${name}?page=${page}&perPage=${pageSize}`, {
                auth: {
                    username: auth.username,
                    password: auth.password,
                }
            });
            setCargando(false);
            setTotal(res.data.total);
            setTotalError(res.data.totalErrorStatus);
            setDataApi(res.data.data);
        } 
        catch (error) {
            setCargando(false);
            console.log(error);
            errorMen();
        }
    }
    useEffect(() => {
        apiFindData();
    }, [page, pageSize])

    const columns = [
        {
            title: 'idBodega',
            dataIndex: 'idBodega',
            key: '1',
            width: 100,
        },
        {
            title: 'nud',
            dataIndex: 'nud',
            key: '2',
            width: 100,
        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: '3',
            width: 200,
        },
        {
            title: 'TELUNO',
            dataIndex: 'teluno',
            key: '4',
            width: 150,
        },
        {
            title: 'FRECUENCIA',
            dataIndex: 'frecuencia',
            key: '12',
            width: 150,
        },
        {
            title: 'CORREO',
            dataIndex: 'correo',
            key: '13',
            width: 220,
        },
        {
            title: 'CONTACTO',
            dataIndex: 'contacto',
            key: '14',
            width: 150,
        },
        {
            title: 'Ruta',
            dataIndex: 'ruta',
            key: '15',
            width: 150,
        },
        {
            title: 'Token',
            dataIndex: 'token',
            key: '16',
            width: 250,
        },
        {
            title: 'Dia Sin Compra',
            dataIndex: 'diasincompra',
            key: '17',
            width: 150,
        },
        {
            title: 'Marcador',
            dataIndex: 'marcador',
            key: '18',
            width: 150,
        },
        {
            title: 'AppId',
            dataIndex: 'appid',
            key: '19',
            width: 120,
        },
        {
            title: 'Opcion',
            dataIndex: 'opcion',
            key: '20',
            width: 100,
        },
        {
            title: 'Uq',
            dataIndex: 'uq',
            key: '21',
            width: 100,
        },
        {
            title: 'Estatus de Envío',
            dataIndex: 'estatus',
            key: '22',
            fixed: 'right',
            width: 150,
        },
    ];

    // Validacion de columnas
    if (validColumnUno) {
        columns.splice(4, 0, {
            title: 'TIPOTELUNO',
            dataIndex: 'tipoteluno',
            key: '5',
            width: 150,
        });
    }
    if (validColumnDos) {
        columns.splice(5, 0, {
            title: 'TELDOS',
            dataIndex: 'teldos',
            key: '6',
            width: 150,
        });
    }
    if (validColumnTres) {
        columns.splice(6, 0, {
            title: 'TIPOTELDOS',
            dataIndex: 'tipoteldos',
            key: '7',
            width: 150,
        });
    }
    if (validColumnCuatro) {
        columns.splice(7, 0, {
            title: 'TELTRES',
            dataIndex: 'teltres',
            key: '8',
            width: 150,
        });
    }
    if (validColumnCinco) {
        columns.splice(8, 0, {
            title: 'TIPOTELTRES',
            dataIndex: 'tipoteltres',
            key: '9',
            width: 150,
        });
    }
    if (validColumnSeis) {
        columns.splice(9, 0, {
            title: 'TELCUATRO',
            dataIndex: 'telcuatro',
            key: '10',
            width: 150,
        });
    }
    if (validColumnSiete) {
        columns.splice(10, 0, {
            title: 'TIPOTELCUATRO',
            dataIndex: 'tipotelcuatro',
            key: '11',
            width: 150,
        });
    }
    
    const [data, setData] = useState([]);
    useEffect(() => {
        const arr = [];
        dataApi.map((item, index) => (
            arr.push({
                key: index+1,
                idBodega: item.idBodega,
                nud: item.nud,
                nombre: item.nombre,
                teluno: item.telefono1,
                tipoteluno: item.tipoteluno,
                teldos: item.telefono2,
                tipoteldos: item.tipoteldos,
                teltres: item.telefono3,
                tipoteltres: item.tipoteltres,
                telcuatro: item.telefono4,
                tipotelcuatro: item.tipotelcuatro,
                frecuencia: item.frecuencia,
                correo: item.correo,
                contacto: item.contacto,
                ruta: item.ruta,
                token: item.token,
                diasincompra: item.diasincompra,
                marcador: item.marcador,
                appid: item.appId,
                opcion: item.opcion,
                uq: item.uq,
                estatus: item.status==="ERROR"
                    ? <div style={{display: "flex", justifyContent: "center"}}><Tag color="red">ERROR</Tag></div>
                    : <div style={{display: "flex", justifyContent: "center"}}><Tag color="green">SUCCESS</Tag></div>
                ,
            })
        ))
        setData(arr);

        const isTipoTelUno = arr.some(item => item.tipoteluno !== "");
        setValidColumnUno(isTipoTelUno);
        const isTelDos = arr.some(item => item.teldos !== "");
        setValidColumnDos(isTelDos);
        const isTipoTelDos = arr.some(item => item.tipoteldos !== "");
        setValidColumnTres(isTipoTelDos);
        const isTelTres = arr.some(item => item.teltres !== "");
        setValidColumnCuatro(isTelTres);
        const isTipoTelTres = arr.some(item => item.tipoteltres !== "");
        setValidColumnCinco(isTipoTelTres);
        const isTelCuatro = arr.some(item => item.telcuatro !== "");
        setValidColumnSeis(isTelCuatro);
        const isTipoTelCuatro = arr.some(item => item.tipotelcuatro !== "");
        setValidColumnSiete(isTipoTelCuatro);
    }, [dataApi])

    const apiDescarga = async () => {
        try {
            const res = await axios.get(`${Settings.redfieldPushDomain}/notifications/csv/download/${name}`, {
                responseType: 'blob',
                auth: {
                    username: auth.username,
                    password: auth.password,
                }
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${name}.csv`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } 
        catch (error) {
            console.log(error);
            errorMen();
        }
    }

    return (
        <div style={{position: "relative", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Text style={{fontSize: "20px", fontWeight: "500"}}>RESUMEN</Text>

            <div style={{position: "absolute", top: "-20px", right: "10px"}}>
                <Button onClick={apiDescarga} type="primary" ghost shape="round" icon={<DownloadOutlined />}>Descargar</Button>
            </div>

            <div style={{width: "100%", display: "flex", justifyContent: "space-around"}}>
                <Card bordered={false}>
                    <Statistic
                        title="Registros Totales Cargados"
                        value={total}
                        valueStyle={{ color: 'blue' }}
                        prefix={<ArrowUpOutlined />}
                    />
                </Card>

                <Card bordered={false}>
                    <Statistic
                        title="Registros Enviados Exitosamente a Megaman"
                        value={total - totalError}
                        valueStyle={{ color: '#83F5A7' }}
                        prefix={<CheckCircleOutlined />}
                    />
                </Card>

                <Card bordered={false}>
                    <Statistic
                        title="Registros No Enviados a Megaman"
                        value={totalError}
                        valueStyle={{ color: '#cf1322' }}
                        prefix={<CloseCircleOutlined />}
                    />
                </Card>
            </div>

            <div style={{width: "100%", marginTop: "10px"}}>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowClassName="editable-row"
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        position: ['bottomCenter'],
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        },
                    }}
                    scroll={{
                        x: 1500,
                        y: 240,
                    }}
                    loading={cargando}
                />
            </div>

            {contextHolder}
        </div>
    )
}
