const Settings = {}
const domain = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
// const domain = "https://gepp.dev.kranon.cloud";
const redfieldpushdomain = "https://gepp.rfpush.kranon.cloud";

Settings.staticMedia = '/static/img/'
Settings.redfieldDomain = domain
Settings.redfieldPushDomain = redfieldpushdomain
Settings.redfieldLocalDomain = 'https://gepp.redfield.kranon.cloud'
Settings.loginPath = '/auth/login'
Settings.tokiReport = '/toki/report/range/'
Settings.tokiCampaignsReal = '/toki/report/format/'
Settings.tokiAgents = '/toki/report/agents/range/'
Settings.tokiAgentsReal = '/toki/report/agents/realtime'
Settings.tokiKeyAccountsReport = '/toki/report/cuentaclave/range'
Settings.tokiKeyAccountsReal = '/toki/report/cuentaclave/realtime'
Settings.tokiDigitalsReport = '/toki/report/digital/range'
Settings.tokiDigitalsReal = '/toki/report/digital/realtime'
Settings.meraAuto = '/mera/contactlist/load?clear='
Settings.hanaCampaigns = '/hana/campaigns/date'
Settings.hanaKeyAccountsCamps = '/hana/queues/date'
Settings.hanaContactsCampsQuery = '?division=whatsapp'
Settings.hanaContactsCampsQueryCtc = '?division=contacts'
Settings.hanaContactsCampsQueryPoc = '?division=digital-details-poc'
Settings.hanaStatus = '/hana/campaigns/status'
Settings.jikiSequencesDivisions = '/jiki/sequences/schedules/divisions?organization='
Settings.jikiSequences = '/jiki/sequences/schedules'

export default Settings