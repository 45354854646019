import React from "react";
import { Card } from "antd";

export default function GridHeader({ sequenceLength }) {
  return (
    <div
      className="header"
      style={ sequenceLength >= 6 && sequenceLength !== 0 ?
        {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "0.3rem",
          position: "sticky",
          paddingRight:"1.9rem"
      } :{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        gap: "0.3rem",
        position: "sticky",
      }}
    >
      <Card
        style={{
          width: "18.7%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        Secuencias
      </Card>
      <Card
        style={{
          width: "16%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        LUNES
      </Card>
      <Card
        style={{
          width: "16%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        MARTES
      </Card>
      <Card
        style={{
          width: "16%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        MIERCOLES
      </Card>
      <Card
        style={{
          width: "16%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        JUEVES
      </Card>
      <Card
        style={{
          width: "16%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        VIERNES
      </Card>
      <Card
        style={{
          width: "16%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        SABADO
      </Card>
      <Card
        style={{
          width: "16%",
          background: "#01479B",
          color: "#FCFCFC",
        }}
        bodyStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
        }}
      >
        DOMINGO
      </Card>
    </div>
  );
}
