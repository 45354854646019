import React from "react";
import { Button, Table } from "antd";
import { motion } from "framer-motion";

export default function WhatsAppAgentsHistorical({
  agentsHistorical,
  downloadWhatsAppAgentsHistoricalReport,
}) {
  const columns = [
    {
      title: "Campaña (s) seleccionadas",
      dataIndex: "campaingName",
      key: "campaingName",
    },
  ];
  const variantsCHistorical = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  };

  return (
    <motion.div
      className="historical-container"
      variants={variantsCHistorical}
      initial="hidden"
      animate="visible"
      transition={{
        duration: 1,
      }}
    >
      <div
        className="historical-table-container"
        style={{ width: "100%", height: "50%" }}
      >
        <Table
          dataSource={agentsHistorical.length > 0 ? agentsHistorical : []}
          columns={columns}
          pagination={false}
          size="middle"
          scroll={{ y: 250 }}
        />
      </div>
      <div
        className="dwnld-btn-pagination-container"
        style={{
          width: "100%",
          height: "10%",
          marginTop: "5rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          type="primary"
          size="large"
          onClick={() => downloadWhatsAppAgentsHistoricalReport()}
        >
          Descargar
        </Button>
      </div>
    </motion.div>
  );
}
