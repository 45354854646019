import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Typography, Button, Upload, message, Table, Popconfirm } from 'antd';
import { motion } from "framer-motion";
import axios from 'axios';

const { Title, Text } = Typography;

export const LayoutAsignacion = ({Settings, auth}) => {

    // Para Motion
    const variantsCHistorical = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    };

    // Al subir el archivo CSV
    const [fileList, setFileList] = useState([]);
    const [name, setName] = useState("");
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setName("");
        },
        beforeUpload: (file) => {
            setName(file.name);
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const errorMen = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
        });
    };
    
    // Leer lo que trae el archivo CSV
    const [submit, setSubmit] = useState(false)
    const [loading, setLoading] = useState(false);
    const readCSV = async () => {
        if(fileList.length === 0) {
            errorMen("Sube un archivo CSV para continuar");
        } 
        else {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append(name, fileList[0]);
                const res = await axios.post(`${Settings.redfieldPushDomain}/usersReset/uploadCsv`, formData, {
                    auth: {
                        username: auth.username,
                        password: auth.password,
                    }
                });
                if(res.status===201) {
                    apiTablaUsers();
                    setLoading(false);
                    setSubmit(true)
                }
            } 
            catch (error) {
                setLoading(false);
                errorMen(error.response.data.error);
            }
        }
    }



    // Api Tabla
    const [dataApi, setDataApi] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [loadData, setLoadData] = useState(false)

    const apiTablaUsers = async () => {
        setLoadData(true)
        try {
            const res = await axios.get(`${Settings.redfieldPushDomain}/usersReset/dataUsers/${name}`, {
                auth: {
                    username: auth.username,
                    password: auth.password,
                },
                params: {
                    page: page,
                    perPage: pageSize 
                }
            })
            if(res.status===200) {
                setDataApi(res.data.data)
                setTotal(res.data.total)
                setLoadData(false)
            }
        }
        catch(error) {
            setLoadData(false)
        }
    }
    useEffect(() => {
        if(name!=="") {
            apiTablaUsers()
        }
    }, [page, pageSize])



    // Para descargar csv
    const [loadCVS, setLoadCVS] = useState(false)
    const downloadCSV = async () => {
        setLoadCVS(true)
        try {
            const res = await axios.get(`${Settings.redfieldPushDomain}/usersReset/downloadUsers/${name}`, {
                auth: {
                    username: auth.username,
                    password: auth.password,
                }
            })
            if(res.status===200) {
                setLoadCVS(false)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}`);
                document.body.appendChild(link);
                link.click();
            }
        }
        catch(error) {
            errorMen("Error al descargar el archivo CSV")
            setLoadCVS(false)
        }
    }



    // Formulario de CSV
    function Formulario() {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px"}}>
                <Text style={{fontSize: "16px", fontWeight: "500"}}>Defina los siguientes campos de envío</Text>

                <div style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
                    <Text style={{fontSize: "16px", marginRight: "10px"}}>Archivo Fuente CSV:</Text>
                    <Upload {...props} accept=".csv" maxCount={1}>
                        <Button icon={<UploadOutlined />} disabled={name!==""}>Subir CSV</Button>
                    </Upload>
                </div>

                <div style={{marginTop: "20px"}}>
                    <Popconfirm
                        title="¿Estás seguro de ejecutar el proceso?"
                        onConfirm={readCSV}
                        okText="Si"
                        cancelText="No"
                        zIndex={999999}
                    >
                        <Button loading={loading} type="primary">EJECUTAR</Button>
                    </Popconfirm>
                </div>
            </div>
        )
    }


    function Resume() {

        const columns = [
            {
                title: 'UserName',
                dataIndex: 'username',
                key: '1',
            },
            {
                title: 'UserDivision',
                dataIndex: 'userdivision',
                key: '2',
            },
            {
                title: 'Organización',
                dataIndex: 'organizacion',
                key: '3',
            },
            {
                title: 'QueueName',
                dataIndex: 'queuename',
                key: '4',
            },
            {
                title: 'Fecha/Hora Procesado',
                dataIndex: 'fecha',
                key: '5',
                width: "200px"
            },
            {
                title: 'Status Procesado',
                dataIndex: 'status',
                key: '6',
            },
        ];

        const [data, setData] = useState([]);
        useEffect(() => {
            const arr = [];
            dataApi.map((item, index) => (
                arr.push({
                    key: index+1,
                    username: item.UserName,
                    userdivision: item.UserDivision,
                    organizacion: item.Organization,
                    queuename: item.QueueName,
                    fecha: item.DatetimeProcess,
                    status: (
                        item.StatusProcess==="SUCCESS" ? 
                        <Text type="success">{item.StatusProcess}</Text> : 
                        <Text type="danger">{item.StatusProcess}</Text>
                    )
                })
            ))
            setData(arr);
        }, [dataApi])

        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Title level={5} style={{marginBottom: 0}}>Total de Registros: <span style={{fontWeight: "bold", color: "#143C64"}}>{total}</span></Title>
                    <Button onClick={downloadCSV} type="primary" loading={loadCVS} style={{marginBottom: "10px"}}>DESCARGAR</Button>
                </div>

                <Table
                    className="table-personalized"
                    columns={columns}
                    dataSource={data}
                    rowClassName="editable-row"
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: total,
                        position: ['bottomCenter'],
                        onChange: (page, pageSize) => {
                            setPage(page);
                            setPageSize(pageSize);
                        },
                    }}
                    scroll={{
                        y: 320,
                    }}
                    loading={loadData}
                />
            </div>
        )
    }

    return (
        <motion.div
            className="planifications-container"
            variants={variantsCHistorical}
            initial="hidden"
            animate="visible"
            transition={{
                duration: 1,
            }}
        >
            <Title level={5}>Layout de Asignación</Title>

            {submit ? <Resume /> : <Formulario />}
            
            {contextHolder}
        </motion.div>
    )
}
