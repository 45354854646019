import React from 'react'
import { Result } from "antd"
import { SelectOutlined } from "@ant-design/icons"

export default function SequencesWaitPage() {
    return (
        <div
            className='sequence-wait-page'
            style={{ marginBottom: "2.5rem" }}
        >
            <Result
                icon={<SelectOutlined />}
                title="Selecciona una División."
            />
        </div>
    )
}
