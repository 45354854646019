import React, { useEffect, useState } from 'react'
import { Typography, Button, Input, Select, message, Table, Empty } from 'antd';
import { motion } from "framer-motion";
import axios from 'axios';

export const ResetUsuarios = ({Settings, auth}) => {

    const { Title, Text } = Typography;
    // Data
    const [dataApi, setDataApi] = useState();
    const [emailNew, setEmailNew] = useState("");

    // Para Motion
    const variantsCHistorical = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    };


    // Mensajes de error
    const [messageApi, contextHolder] = message.useMessage();
    const errorMen = (mensaje) => {
        messageApi.open({
            type: 'error',
            content: mensaje,
        });
    };

    // Para descargar csv
    const [loadCVS, setLoadCVS] = useState(false)
    const downloadCSV = async () => {
        setLoadCVS(true)
        try {
            const res = await axios.get(`${Settings.redfieldPushDomain}/usersReset/downloadEmail/${emailNew}`, {
                auth: {
                    username: auth.username,
                    password: auth.password,
                }
            })
            if(res.status===200) {
                setLoadCVS(false)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${emailNew}.csv`);
                document.body.appendChild(link);
                link.click();
            }
        }
        catch(error) {
            errorMen("Error al descargar el archivo CSV")
            setLoadCVS(false)
        }
    }

    // Formulario de reset de usuarios
    function Form() {
        // Consumo de api reset de usuarios
        const [email, setEmail] = useState('');
        const [org, setOrg] = useState('');
        const [loading, setLoading] = useState(false);

        const resetUsers = async () => {
            if(email === '' || org === '') {
                errorMen("Completa todos los campos");
            }
            else {
                setLoading(true);
                try {
                    const res = await axios.post(`${Settings.redfieldPushDomain}/usersReset/sendEmail`, {
                        email: email,
                        organizacion: org 
                    }, {
                        auth: {
                            username: auth.username,
                            password: auth.password,
                        }
                    });
                    if(res.status === 200) {
                        setLoading(false);
                        setDataApi(res.data);
                        setEmailNew(email);
                    }
                } 
                catch (error) {
                    console.log(error);
                    setLoading(false);
                    errorMen(error.response.data.message);
                }
            }
        }

        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "10px"}}>

                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <div style={{width: "45%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <Text style={{fontSize: "16px", marginRight: "10px"}}>Email al que se enviara la información:</Text>
                        <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div style={{width: "45%", display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "20px"}}>
                        <Text style={{fontSize: "16px", marginRight: "10px"}}>Selecciona una Organización:</Text>
                        <Select
                            placeholder="Seleccione una Organización"
                            style={{ width: "100%" }}
                            options={[
                                { value: 'US', label: 'US' },
                                { value: 'CA', label: 'CA' },
                            ]}
                            onChange={(value) => setOrg(value)}
                        />                        
                    </div>
                </div>


                <div style={{marginTop: "20px"}}>
                    <Button onClick={resetUsers} loading={loading} type="primary">EJECUTAR</Button>
                </div>
            </div>
        )
    }



    // Tabla de usuarios
    function TableUser() {
        const columns = [
            {
                title: 'UserName',
                dataIndex: 'username',
                key: '1',
            },
            {
                title: 'UserDivision',
                dataIndex: 'userdivision',
                key: '2',
            },
            {
                title: 'Organización',
                dataIndex: 'organizacion',
                key: '3',
            },
            {
                title: 'QueueName',
                dataIndex: 'queuename',
                key: '4',
            },
            {
                title: 'Fecha/Hora Procesado',
                dataIndex: 'fecha',
                key: '5',
                width: "200px"
            },
            {
                title: 'Status Procesado',
                dataIndex: 'status',
                key: '6',
            },
        ];

        const [data, setData] = useState([]);
        useEffect(() => {
            if(!dataApi.message && dataApi.length>0) {
                const arr = [];
                dataApi.map((item, index) => (
                    arr.push({
                        key: index+1,
                        username: item.UserName,
                        userdivision: item.UserDivision,
                        organizacion: item.Organization,
                        queuename: item.QueueName,
                        fecha: item.DatetimeProcess,
                        status: (
                            item.StatusProcess===true ? 
                            <Text style={{textTransform: "uppercase"}} type="success">SUCCESS</Text> : 
                            <Text style={{textTransform: "uppercase"}} type="danger">ERROR</Text>
                        )
                    })
                ))
                setData(arr);
            }
        }, [dataApi])

        var view = "";
        if(dataApi.message || Object.keys(dataApi).length === 0) {
            view = (
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 150 }}
                    description={
                        <Typography.Text style={{fontSize: "24px"}}>
                            {(dataApi.message!=="Not users" || Object.keys(dataApi).length === 0)
                                ? "No hay correlación con los Queue de Genesys"
                                : "No hay usuarios para resetear"
                            }
                        </Typography.Text>
                    }
                >
                    <Button onClick={() => setDataApi(undefined)} type="primary">REGRESAR</Button>
                </Empty>
            )
        }
        else {
            view = (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Title level={5} style={{marginBottom: 0}}>Total de Usuarios: <span style={{fontWeight: "bold", color: "#143C64"}}>{dataApi.length}</span></Title>
                        <Button onClick={downloadCSV} loading={loadCVS} type="primary" style={{marginBottom: "10px"}}>DESCARGAR</Button>
                    </div>

                    <Table
                        className="table-personalized"
                        columns={columns}
                        dataSource={data}
                        rowClassName="editable-row"
                        pagination={{
                            position: ['bottomCenter'],
                        }}
                        scroll={{
                            y: 320,
                        }}
                    />
                </div>
            )
        }

        return (view)
    }

    return (
        <motion.div
            className="planifications-container"
            variants={variantsCHistorical}
            initial="hidden"
            animate="visible"
            transition={{
                duration: 1,
            }}
        >
            <Title level={5}>Reset de Usuarios</Title>

            {dataApi!==undefined ? <TableUser /> :  <Form />}
           
            {contextHolder}
        </motion.div>
    )
}
