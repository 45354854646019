import React from 'react'
import { Result } from "antd"
import { SelectOutlined } from "@ant-design/icons"

export default function WaitPage() {
  return (
    <div className='wait-page-container'>
      <Result 
        icon={<SelectOutlined />}
        title="Selecciona una o más campañas."
      />
    </div>
  )
}
